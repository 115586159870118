import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Img from "gatsby-image";
import { graphql, useStaticQuery, Link } from "gatsby";
import { connect } from "react-redux";
import { login, signUp } from "../state/actions/auth";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";

import styles from "../styles/components/loginModal.module.scss";

const LoginModal = (props) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  // Signup or Login
  const [isLogin, setIsLogin] = useState(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignIn = (e) => {
    e.preventDefault();
    props.login({ email, password });
  };

  // Signup or Login
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleCreateAccount = (e) => {
    e.preventDefault();
    if (name && tel && email && password && confirmPassword) {
      if (password === confirmPassword) {
        props.signUp({ name, tel, email, password });
      } else {
        toast.error("Passwords do not match");
      }
    } else {
      toast.error("Please fill all required fields!");
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.hide}
      dialogClassName={styles.modal}
      centered
    >
      <Modal.Body
        className={
          isLogin
            ? `${styles.container}`
            : `${styles.container} ${styles.rightPanelActive}`
        }
      >
        <div className={`${styles.formContainer} ${styles.signup}`}>
          {/* Sign Up */}
          <Form className={styles.form}>
            <h1 className={styles.formTitle}>
              Sign <span>Up</span>
            </h1>
            <p className="small">Please fill in the form with your details</p>

            <div className={styles.formInput}>
              <Form.Group>
                <Form.Control
                  type="text"
                  size="lg"
                  value={name}
                  placeholder="Full Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="tel"
                  size="lg"
                  value={tel}
                  placeholder="Phone Number"
                  onChange={(e) => setTel(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="email"
                  size="lg"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="password"
                  size="lg"
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="password"
                  size="lg"
                  value={confirmPassword}
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Form.Group>
            </div>
            <Button
              variant=""
              size="lg"
              type="submit"
              disabled={
                !name || !tel || !email || !password || !confirmPassword
              }
              className={styles.submitBtn}
              onClick={handleCreateAccount}
            >
              Create Account
            </Button>
          </Form>
        </div>
        <div className={`${styles.formContainer} ${styles.signin}`}>
          <form className={styles.form}>
            <h1 className={styles.formTitle}>
              Sign <span>In</span>
            </h1>
            <p className="small">Please login to continue</p>

            <div className={styles.formInput}>
              <input
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <Button
              type="submit"
              variant=""
              disabled={!email || !password}
              size="lg"
              className={` ${styles.submitBtn} mt-3`}
              onClick={handleSignIn}
            >
              Sign In
            </Button>
            <Link
              to="/reset"
              onClick={props.hide}
              className="p small mt-3 text-black-50"
            >
              Forgot Password?
            </Link>
          </form>
        </div>
        <div className={styles.overlayContainer}>
          <div className={styles.overlay}>
            <div className={`${styles.overlayPanel} ${styles.overlayLeft}`}>
              <div className={styles.logo}>
                <Img
                  fluid={data.logo.childImageSharp.fluid}
                  style={{ height: "100%", width: "100%" }}
                  alt="Blazen Supplements"
                />
              </div>
              <div className={styles.overlayDesc}>
                <h1>Existing User?</h1>
                <p className="small">Already have an account with us?</p>
              </div>
              <button
                className={styles.changeModeBtn}
                onClick={() => setIsLogin(true)}
              >
                Sign In
              </button>
            </div>
            <div className={`${styles.overlayPanel} ${styles.overlayRight}`}>
              <div className={styles.logo}>
                <Img
                  fluid={data.logo.childImageSharp.fluid}
                  style={{ height: "100%", width: "100%" }}
                  alt="Blazen Supplements"
                />
              </div>
              <div className={styles.overlayDesc}>
                <h1>New User?</h1>
                <p className="small">
                  Create an account to start shopping with us!
                </p>
              </div>
              <button
                className={styles.changeModeBtn}
                onClick={() => setIsLogin(false)}
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(login(data)),
    signUp: (data) => dispatch(signUp(data)),
  };
};

export default connect(undefined, mapDispatchToProps)(LoginModal);
