import React from "react";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import Header from "./header";
import Footer from "./footer";

import "react-toastify/dist/ReactToastify.css";
import styles from "../styles/components/layout.module.scss";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className={styles.toastContainer}
      />
      <div className={styles.layout}>
        <main>{children}</main>
      </div>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
