import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";
import { Modal, Form, Button } from "react-bootstrap";
import { login, signUp } from "../state/actions/auth";
import { toast } from "react-toastify";

import styles from "../styles/components/loginModalMobile.module.scss";

const LoginModal = (props) => {
  // Signup or Login
  const [isLogin, setIsLogin] = useState(true);
  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [tel, setTel] = useState("");

  const handleSignIn = (e) => {
    e.preventDefault();
    props.login({ email, password });
  };

  const handleCreateAccount = (e) => {
    e.preventDefault();
    if (name && tel && email && password && confirmPassword) {
      if (password === confirmPassword) {
        props.signUp({ name, tel, email, password });
      } else {
        toast.error("Passwords do not match");
      }
    } else {
      toast.error("Please fill all required fields!");
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.hide}
      centered
      contentClassName={styles.modal}
    >
      {isLogin ? (
        <div>
          {/* Sign In */}
          <Form className={styles.form}>
            <h1 className={styles.formTitle}>
              Sign <span>In</span>
            </h1>
            <p className="small">Please login to continue</p>

            <div className={styles.formInput}>
              <input
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <Button
              type="submit"
              variant=""
              disabled={!email || !password}
              size="lg"
              className={` ${styles.submitBtn} mt-3`}
              onClick={handleSignIn}
            >
              Sign In
            </Button>
            <Link
              to="/reset"
              onClick={props.hide}
              className="p small text-black-50"
            >
              Forgot Password?
            </Link>
          </Form>

          <p className="small mt-5 text-center">
            New User?{" "}
            <span
              className={styles.action}
              onClick={() => {
                setIsLogin(!isLogin);
              }}
              role="button"
            >
              Create an account
            </span>
          </p>
        </div>
      ) : (
        <div>
          {/* Sign Up */}
          <Form className={styles.form}>
            <h1 className={styles.formTitle}>
              Sign <span>Up</span>
            </h1>
            <p className="small">Please fill in the form with your details</p>

            <div className={styles.formInput}>
              <input
                type="text"
                value={name}
                placeholder="Full Name"
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="tel"
                value={tel}
                placeholder="Phone Number"
                onChange={(e) => setTel(e.target.value)}
              />
              <input
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                type="password"
                value={confirmPassword}
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <Button
              variant=""
              size="lg"
              type="submit"
              disabled={
                !name || !tel || !email || !password || !confirmPassword
              }
              className={styles.submitBtn}
              onClick={handleCreateAccount}
            >
              Create Account
            </Button>
          </Form>

          <p className="small mt-5 text-center">
            Already have an account?{" "}
            <span
              className={styles.action}
              onClick={() => {
                setIsLogin(!isLogin);
              }}
              role="button"
            >
              Sign In
            </span>
          </p>
        </div>
      )}
      <Modal.Body>{/* Sign Up */}</Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(login(data)),
    signUp: (data) => dispatch(signUp(data)),
  };
};

export default connect(undefined, mapDispatchToProps)(LoginModal);
