import React from "react";

import "./hamburger.scss";

const HamburgerIcon = (props) => {
  return (
    <div
      id="nav-icon1"
      role="button"
      onKeyDown={props.onClickHandler}
      className={props.menuOpen ? "open" : "close"}
      onClick={props.onClickHandler}
      tabIndex={0}
    >
      <span />
      <span />
      <span />
    </div>
  );
};

export default HamburgerIcon;
