import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import { Link } from "gatsby";
import LoginModal from "./loginModal";
import LoginModalMobile from "./loginModalMobile";
import { connect } from "react-redux";
import styles from "../styles/components/header.module.scss";
// import { AiOutlineSearch } from "react-icons/ai";
import { HiShoppingCart } from "react-icons/hi";
import { FaUser } from "react-icons/fa";
import useWindowDimensions from "../utils/useWindowDimensions";
import Dropdown from "react-bootstrap/Dropdown";
import { logout } from "../state/actions/auth";
import { navigate } from "gatsby";
import NavDrawer from "./navDrawer";

const Header = (props) => {
  const { width } = useWindowDimensions();
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo-white-horizontal.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  // const [searchTerm, setSearchTerm] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLoginModalMobile, setShowLoginModalMobile] = useState(false);

  const handleUser = () => {
    if (width > 768) {
      setShowLoginModal(true);
    } else {
      setShowLoginModalMobile(true);
    }
  };

  const handleLogout = () => {
    props.logout();
    setShowLoginModal(false);
    setShowLoginModalMobile(false);
  };

  return (
    <>
      <LoginModal
        show={!props.isLoggedIn && showLoginModal}
        hide={() => setShowLoginModal(false)}
      />
      <LoginModalMobile
        show={!props.isLoggedIn && showLoginModalMobile}
        hide={() => setShowLoginModalMobile(false)}
      />

      <header className={styles.header}>
        <div className={styles.navContent}>
          <div className={styles.logo}>
            <Link to="/">
              <Img
                fixed={data.file.childImageSharp.fixed}
                style={{ width: "100%", height: "100%" }}
                objectFit="contain"
              />
            </Link>
          </div>
          <div className={`${styles.links}`}>
            <Link to="/" activeClassName={styles.active}>
              Home
            </Link>

            <Link to="/contact" activeClassName={styles.active}>
              Get In Touch
            </Link>

            <Link
              to="/shop"
              activeClassName={styles.primaryActive}
              partiallyActive={true}
              className={styles.primary}
            >
              Shop
            </Link>
            <div className={styles.btnDiv}>
              <div className={styles.cartBtn}>
                <Link to="/app/checkout" activeClassName={styles.active}>
                  <HiShoppingCart />
                  {props.cart.length > 0 && (
                    <div className={styles.cartQty}>
                      <p>{props.cart.length}</p>
                    </div>
                  )}
                </Link>
              </div>

              {props.isLoggedIn ? (
                <Dropdown>
                  <Dropdown.Toggle
                    className={styles.userBtn}
                    variant=""
                    style={{ color: "#707070" }}
                    size="lg"
                  >
                    <FaUser />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className={styles.userMenu}>
                    {props.isAdmin && (
                      <>
                        <Dropdown.Item
                          onClick={() => {
                            navigate("/app/admin");
                          }}
                        >
                          Admin Panel
                        </Dropdown.Item>
                      </>
                    )}

                    <Dropdown.Item
                      onClick={() => {
                        navigate("/app/user");
                      }}
                    >
                      My Account
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <div className={styles.userBtn} onClick={handleUser}>
                  <FaUser />
                </div>
              )}

              {/* <div>
            <input
              className={styles.searchInput}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <AiOutlineSearch className={styles.searchBtn} />
          </div> */}
            </div>
          </div>
          <div className={styles.navdrawer}>
            <div className={styles.cartBtn}>
              <Link
                to={props.cart.length > 0 ? "/app/checkout" : "/shop"}
                activeClassName={styles.active}
              >
                <HiShoppingCart
                  color="white"
                  style={{
                    fontSize: "3rem",
                    marginTop: "-6px",
                  }}
                />
                {props.cart.length > 0 && (
                  <div className={styles.cartQty}>
                    <p>{props.cart.length}</p>
                  </div>
                )}
              </Link>
            </div>
            <NavDrawer login={handleUser} logout={handleLogout} />
          </div>
        </div>
      </header>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    isLoggedIn: state.auth.isLoggedIn,
    isAdmin: state.auth.auth?.user.admin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
