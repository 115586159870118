import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { Link } from "gatsby";
import {
  FaHome,
  FaShoppingBag,
  FaPhone,
  FaUser,
  FaUserLock,
  FaShoppingCart,
} from "react-icons/fa";
import { RiLoginBoxFill, RiLogoutBoxFill } from "react-icons/ri";
import Logo from "./logo-white";
import HamburgerIcon from "./hamburgerIcon";

import "./navDrawer.scss";

const styles = {
  list: {
    width: "100%",
  },
  paper: {
    width: 250,
    background: "#192024",
    fontSize: "1.2rem",
  },
};

class TemporaryDrawer extends React.Component {
  state = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {
    const { classes } = this.props;
    const sideList = (
      <div>
        <div className={classes.list}>
          <div
            style={{
              alignSelf: `left`,
              margin: `30px auto`,
              height: `75px`,
              width: `75px`,
            }}
          >
            <Logo />
          </div>
          <List>
            <Link to="/">
              <ListItem button>
                <h5 className="navDrawer__link">
                  <ListItemIcon>
                    <FaHome />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </h5>
              </ListItem>
            </Link>
            <Link to="/shop">
              <ListItem button>
                <h5 className="navDrawer__link">
                  <ListItemIcon>
                    <FaShoppingBag />
                  </ListItemIcon>
                  <ListItemText primary="Shop" />
                </h5>
              </ListItem>
            </Link>
            <Link to="/contact">
              <ListItem button>
                <h5 className="navDrawer__link">
                  <ListItemIcon>
                    <FaPhone />
                  </ListItemIcon>
                  <ListItemText primary="Contact" />
                </h5>
              </ListItem>
            </Link>
            <Link to="/app/checkout">
              <ListItem button>
                <h5 className="navDrawer__link">
                  <ListItemIcon>
                    <FaShoppingCart />
                  </ListItemIcon>
                  <ListItemText primary="Cart" />
                </h5>
              </ListItem>
            </Link>
            {this.props.isLoggedIn ? (
              <>
                <Link to="/app/user">
                  <ListItem button>
                    <h5 className="navDrawer__link">
                      <ListItemIcon>
                        <FaUser />
                      </ListItemIcon>
                      <ListItemText primary="My Account" />
                    </h5>
                  </ListItem>
                </Link>
                {this.props.isAdmin && (
                  <Link to="/app/admin">
                    <ListItem button>
                      <h5 className="navDrawer__link">
                        <ListItemIcon>
                          <FaUserLock />
                        </ListItemIcon>
                        <ListItemText primary="Admin" />
                      </h5>
                    </ListItem>
                  </Link>
                )}

                <ListItem onClick={() => this.props.logout()}>
                  <h5 className="navDrawer__link">
                    <ListItemIcon>
                      <RiLogoutBoxFill />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </h5>
                </ListItem>
              </>
            ) : (
              <ListItem onClick={() => this.props.login()}>
                <h5 className="navDrawer__link">
                  <ListItemIcon>
                    <RiLoginBoxFill />
                  </ListItemIcon>
                  <ListItemText primary="Login" />
                </h5>
              </ListItem>
            )}
          </List>
        </div>
      </div>
    );

    return (
      <div>
        <div className="hamburgerIcon">
          <HamburgerIcon
            onClickHandler={this.toggleDrawer("right", true)}
            style={{ cursor: "pointer" }}
            menuOpen={this.state.right}
          />
          <Drawer
            classes={{ paper: classes.paper }}
            anchor="left"
            open={this.state.right}
            onClose={this.toggleDrawer("right", false)}
          >
            <div
              tabIndex={0}
              role="button"
              onClick={this.toggleDrawer("right", false)}
              onKeyDown={this.toggleDrawer("right", false)}
            >
              {sideList}
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    isAdmin: state.auth.auth?.user.admin,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(TemporaryDrawer));
