import axios from "axios";
import { toast } from "react-toastify";

import { isBrowser } from "../utils";
import { navigate } from "gatsby";

export const login = (auth) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.GATSBY_API_CALL}/users/login`,
        auth
      );

      dispatch({
        type: "LOGIN",
        data: res.data,
      });
      isBrowser() && localStorage.setItem("auth", JSON.stringify(res.data));
      toast.success(
        `Welcome back ${res.data.user.name || res.data.user.email}!`
      );
    } catch (e) {
      toast.error(`Error: ${e.response.data.error.message}`);
    }
  };
};

export const signUp = (details) => {
  return async (dispatch) => {
    const accountData = {
      name: details.name,
      phoneNumber: details.tel,
      email: details.email,
      password: details.password,
    };

    try {
      const res = await axios.post(
        `${process.env.GATSBY_API_CALL}/users`,
        accountData
      );
      dispatch(autoLogin(res.data));
    } catch (e) {
      toast.error(
        `Error: ${
          e.response.data.message || e.response.data || "Please try again."
        }`
      );
    }
  };
};

export const logout = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token;
    try {
      await axios.post(
        `${process.env.GATSBY_API_CALL}/users/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      (await isBrowser) && localStorage.removeItem("auth");
      (await isBrowser) && localStorage.removeItem("cart");
      (await isBrowser) && localStorage.removeItem("shipping");
      (await isBrowser) && localStorage.removeItem("billing");
      (await isBrowser) && localStorage.removeItem("phoneNumber");

      await dispatch({
        type: "USER_LOGOUT",
      });
      toast.success(`Successfully logged out.`);
    } catch (e) {
      toast.error(`Error: ${e.response.data.error.message}`);
    }
  };
};

export const autoLogin = (auth) => {
  return async (dispatch) => {
    dispatch({
      type: "LOGIN",
      data: auth,
    });
    isBrowser() && localStorage.setItem("auth", JSON.stringify(auth));
    navigate("/");
    toast.success(`Welcome back ${auth.user.name || auth.user.email}!`);
  };
};
