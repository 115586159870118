import React, { useState } from "react";
import Logo from "./logo-white";
import { Link } from "gatsby";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import { Container, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import styles from "../styles/components/footer.module.scss";

// Form Helpers
function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    console.log("handleSubmit");
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "subscribe",
        email,
      }),
    })
      .then(() => {
        toast.success("Thank you for subscribing!");
        setEmail("");
      })
      .catch((error) => alert(error));
  };

  return (
    <div className={styles.footer}>
      <Container fluid className={styles.wrapper}>
        <div>
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.copyrights}>
            Copyright © {new Date().getFullYear()}. All Rights Reserved.
          </div>
        </div>

        <div className={styles.links}>
          <Link to="/" className={styles.link}>
            Home
          </Link>
          <Link to="/shop" className={styles.link}>
            Shop
          </Link>
          <Link to="/about" className={styles.link}>
            About
          </Link>
          <Link to="/contact" className={styles.link}>
            Contact
          </Link>
        </div>
        <div className={styles.links}>
          <Link to="/terms" className={styles.link}>
            Terms &amp; Conditions
          </Link>
          <Link to="/privacy" className={styles.link}>
            Privacy Policy
          </Link>
          <Link to="/refunds" className={styles.link}>
            Refunds
          </Link>
        </div>
        <div className={styles.subs}>
          <h6 className="d-lg-none">Subscribe</h6>
          <p className="small">
            Subscribe to our newsletter to keep up to date on our latest offers!
          </p>
          <div>
            <Form
              name="subscribe"
              onSubmit={handleSubmit}
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              className={styles.subInput}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="subscribe" />
              <label style={{ display: "none" }}>
                Don’t fill this out: <input name="bot-field" />
              </label>
              <Form.Control
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                size="lg"
                variant="subscribe"
                type="submit"
                disabled={!email}
              >
                Subscribe
              </Button>
            </Form>
          </div>
          <div className={styles.social}>
            <a
              href="https://www.facebook.com/blazensupplements/"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillFacebook />
            </a>
            <a
              href="https://www.instagram.com/blazen_supplements/"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillInstagram />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
