import { useState, useEffect } from "react";
import isBrowser from "./isBrowser";

function getWindowDimensions() {
  let dimensions = {};
  if (isBrowser()) {
    dimensions = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  return dimensions;
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
